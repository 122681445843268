import React from "react"

import volunteerWork from "../../assets/icons/noun_Hand_3604017.svg"
import workkLifeBalance from "../../assets/icons/noun_Work Balance_3782589.svg"
import hiringDiversity from "../../assets/icons/noun_hiring diversity_3990018.svg"
import climateChange from "../../assets/icons/noun_Climate Change_8674.svg"

const data = [
  {
    id: 3,
    icon: volunteerWork,
    name: "Volunteer work",
  },
  {
    id: 4,
    icon: workkLifeBalance,
    name: "Work Life Balance",
  },
  {
    id: 5,
    icon: hiringDiversity,
    name: "Diversity Hiring",
  },
  {
    id: 6,
    icon: climateChange,
    name: "Climate Change",
  },
]

const IndustriesWeServe = () => {
  return (
    <div className="industries-serve-area ptb-80 bg-003c64">
      <div className="container">
        <div className="section-title">
          <h2>Corporate and Social Responsibility</h2>
          <div className="bar"></div>
          <p>Some of the pillars of our CSR program include.</p>
        </div>

        <div className="row justify-content-center">
          {data.map(item => (
            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              key={item.id}
              style={{ marginTop: "20px" }}
            >
              <div key={item.id} className="single-industries-serve-box">
                <div className="icon">
                  <img src={item.icon} alt={item.name} />
                </div>

                <div style={{ paddingLeft: "10px" }}>{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="analytics-shape2">
        {/* <StaticImage
          src="../../assets/images/bigdata-analytics/vector.png"
          alt="Some of the pillars of our CSR program include."
        /> */}
      </div>
    </div>
  )
}

export default IndustriesWeServe
