import React from "react"

import { StaticImage } from "gatsby-plugin-image"

const Features = () => {
  return (
    <>
      <div className="iot-features-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 iot-features-image">
              <StaticImage
                src="../../assets/images/life-at-atyeti.png"
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="Our Culture"
                loading="eager"
              />
            </div>

            <div className="col-lg-6 iot-features-content pr-3" style={{paddingRight:{lg:"8rem"}}}>
              <h3>Our Culture</h3>
              <p>
                Culture is vital to our organization because it shapes our
                identity and drives our growth. Our culture is defined by a set
                of behavioral and procedural norms. The core of our culture is
                the mentality of “Exceeding Expectations” . Built around that
                core, are people who are highly motivated, work with colleagues
                and customers in a trusting environment and have a clear sense
                of purpose focused on our long term goals.{" "}
              </p>
              <ul className="features-details-list">
                <li>
                  Our teams are encouraged not to fear failure because that
                  fosters a culture of learning within our team.
                </li>

                <li>
                  We are committed to doing the right thing and that is why we
                  enjoy a positive reputation with customers, employees, and
                  partners.
                </li>

                <li>
                  Our fun culture stems from the energy and the vibe of our
                  coworkers. Come join us and add to our culture.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Features
