import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Footer from "../components/_App/Footer"
import IndustriesWeServe from "../components/Shared/IndustriesWeServe"
import Navbar from "../components/_App/Navbar"
import LearningAndDevelopment from "../components/Shared/Learning-And-Development"
import OurCulture from "../components/Shared/OurCulture"

const DigitalAgencyPortfolio = () => (
  <Layout>
    <Seo
      title="Life At Atyeti "
      description=" Atyeti's culture is built with the mentality of “Exceeding Expectations”. Built around that core, are people who are highly motivated, work with colleagues and customers in a trusting environment and have a clear sense of purpose focused on our long term goals."
      keyword=" Life, work culture, employee life, life at atyeti, human resources, corporate social responsibility, learning and development"
    />
    <Navbar />
    <OurCulture />

    <div className="creative-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="creative-inner-area"></div>
          </div>
        </div>
      </div>
    </div>

    <IndustriesWeServe pb-80 />
    <LearningAndDevelopment />
    {/* <Feedback /> */}
    <Footer />
  </Layout>
)

export default DigitalAgencyPortfolio
