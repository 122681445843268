import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Features = () => {
  return (
    <>
      <div className="iot-features-area1 ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 iot-features-content iot-features-content1 p-md-0">
              <h3>Learning and Development</h3>
              <ul className="features-details-list">
                <li>
                  Atyeti is very committed to investing in employee development
                  and training. Our upskilling, cross skilling, certification
                  policy are designed to bring out the best in you.{" "}
                </li>
                <li>
                  For graduates fresh out of College, we will train you in
                  various technical skills, communication skills, critical
                  thinking, teamwork and give you fast track leadership
                  opportunities.
                </li>
                <li>
                  We believe in continuous learning. The company runs regular
                  programs to cross train employees with both formal and
                  informal education that allows you to expand your skill set to
                  adapt to an ever-changing environment.
                </li>
                <li>
                  {" "}
                  We have partnership with great companies and we encourage all
                  our employees to get certified in their core competencies. We
                  are here to support your career growth.
                </li>
              </ul>
            </div>

            <div className="col-lg-6 iot-features-image pt-4 p-md-0">
              <StaticImage
                src="../../assets/images/life.png"
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="Learning and Development"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Features
